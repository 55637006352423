import React, { Component, useCallback } from 'react'
// import { DropzoneArea } from 'material-ui-dropzone';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { withSnackbar } from 'notistack';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { getCookie } from './Utils/CookieHelper';
import { blobServiceClientFunc } from './Helper/BlobHelper';

const dark = getCookie('theme_mode')


class Blob extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: true,
            files: [],
            submitDisabled: true,
            progress: 0,
            loading: false
        }
    }

    componentDidMount(){
        console.log(this.props)
    }

    render() {


        const uploadFiles = async () => {
            const blobServiceClient = blobServiceClientFunc();

            const containerName = this.props.containerName;

            const containerClient = blobServiceClient.getContainerClient(containerName);

            try {
                const promises = [];
                this.setState({ loading: true })
                var total_files_size = 0
                var file_name_array = []
                var obj = {}
                for (let i = 0; i < this.state.files.length; i++) {
                    const element = this.state.files[i];
                    if (element) {
                        total_files_size += element.size
                        obj[element.name] = 0
                    }
                }
                for (let i = 0; i < this.state.files.length; i++) {
                    const file = this.state.files[i];
                    if (file) {
                        var file_extension = "." + file.name.split('.').slice(1).join('.');
                        
                        var file_name = this.props.fileName + file_extension
                        file_name_array.push(containerName + "/" + file_name)
                        const blockBlobClient = containerClient.getBlockBlobClient(file_name);
                        promises.push(blockBlobClient.uploadData(file, {
                            onProgress: ev => {
                                obj[file.name] = ev.loadedBytes
                                let total = 0
                                for (let j = 0; j < Object.keys(obj).length; j++) {
                                    const element = Object.keys(obj)[j];
                                    total += obj[element]
                                }
                                this.setState({ progress: (total / total_files_size) * 100 })
                            }
                        }));
                    }
                }
                // for (const file of this.state.files) {
                //     // var customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
                //     // blobService.singleBlobPutThresholdInBytes = customBlockSize;
                //     var file_extension = file.name.split('.').pop()
                //     var file_name = this.props.project.id + "_" + this.props.asset.id + "_" + this.props.version.id + "." + file_extension
                //     const blockBlobClient = containerClient.getBlockBlobClient(file_name);
                //     promises.push(blockBlobClient.uploadData(file, {
                //         onProgress: ev => {
                //             loaded_bytes = ev.loadedBytes
                //             this.setState({ progress: (loaded_bytes / total_files_size) * 100 }, () => {
                //                 console.log(loaded_bytes, total_files_size,ev)
                //             })
                //         }
                //     }));
                // }
                await Promise.all(promises);
                this.setState({ loading: false })
                this.props.updateBlob(file_name_array, this.props.version)
            }
            catch (error) {
                console.log(error.message);
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            }
        }
        
        

        return (
            <div>
                <DialogContent>
                    <AcceptMaxFiles files_type={this.props.filesType} onDrop={(files) => this.setState({ files }, () => { this.setState({ submitDisabled: !(files.length > 0) }) })} />
                    {/* <DropzoneArea
                        filesLimit={1}
                        maxFileSize={10737418240}
                        onChange={(files) => this.setState({ submitDisabled: files.length > 0 ? false : true })}
                        onDrop={(files) => this.setState({ files })}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={() => { this.props.onClose(); this.setState({ submitDisabled: true }) }}>
                        close
                    </Button>
                    <Button disabled={this.state.submitDisabled} color='primary' variant='contained' onClick={() => uploadFiles()}>
                        submit
                    </Button>
                </DialogActions>

                <Dialog maxWidth="lg" fullWidth={true} open={this.state.loading}  >
                    <DialogTitle marginTop="2px" spacing={2}>Uploading...</DialogTitle>
                    <DialogContent>
                        <Box display="flex" alignItems="center">
                            <Box width="100%" mr={1}>
                                <LinearProgress variant="determinate" value={this.state.progress} />
                            </Box>
                            <Box minWidth={35}>
                                <Typography variant="body2" color="primary">{`${Math.round(
                                    this.state.progress,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default withSnackbar(Blob)

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: dark ? '#272727' : '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: "pointer"
};

function AcceptMaxFiles(props) {
    const onDrop = useCallback(acceptedFiles => {
        props.onDrop(acceptedFiles)
    }, [props])

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles: 4,
        onDrop,
        // accept: props.files_type
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </li>
        )
    });


    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })} style={baseStyle} >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <em>(4 files are the maximum number of files you can drop here)</em>
            </div>
            <aside>
                <h4>Accepted files</h4>
                <ul>{acceptedFileItems}</ul>
                <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul>
            </aside>
        </section>
    );
}
